<template>
  <!-- eslint-disable no-trailing-spaces max-len -->
  <div>
    <h2>The Wedding Party</h2>

    <divider
      content="Bridesmaids"
      left
    />

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'bride/megan-a.jpg',
            'bride/megan-b.jpg',
            'bride/megan-c.jpg',
            'bride/megan-d.jpg',
            'bride/megan-e.jpg',
            'bride/megan-f.jpg',
            'bride/megan-g.jpg'
          ]"
          title="Megan Church"
        />
      </div>
      <div class="text">
        <p class="title">
          Megan Church
        </p>
        <p class="subtitle">
          Maid of Honor
        </p>
        <p>
          Megan is Lindsey’s older sister. She taught Lindsey
          about basically everything she’s ever liked, most notably Buffy, podcasts,
          musical theater, and Harry Potter.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'bride/sean-a.jpg',
            'bride/sean-b.jpg',
            'bride/sean-c.jpg',
            'bride/sean-d.jpg'
          ]"
          title="Sean Dunn"
        />
      </div>
      <div class="text">
        <p class="title">
          Sean Dunn
        </p>
        <p class="subtitle">
          Bridesmaid
        </p>
        <p>
          Sean was Lindsey’s college roommate. They met in the
          Swing Kids club at UW Sean’s freshman year and bonded over Irish drinking
          songs, party planning, and Crazy Ex-Girlfriend. She is an honorary member
          of the Judge family.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'bride/astird-a.jpg',
            'bride/astird-b.jpg',
            'bride/astird-c.jpg',
            'bride/astird-d.jpg',
            'bride/astird-e.jpg',
            'bride/astird-f.jpg',
            'bride/astird-g.jpg'
          ]"
          title="Katelyn &quot;Astird&quot; Orchard"
        />
      </div>
      <div class="text">
        <p class="title">
          Katelyn &quot;Astird&quot; Orchard
        </p>
        <p class="subtitle">
          Bridesmaid
        </p>
        <p>
          Katelyn is Lindsey’s best friend from high school. They
          met in freshman French class and bonded over The Office, Avenue Q, and
          creating their collective boyfriend Bob. Lindsey gave her her nickname
          Astird after the day that episode of The Office aired because her French
          name was Astrid.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'bride/kelsie-a.jpg',
            'bride/kelsie-b.jpg',
            'bride/kelsie-c.jpg',
            'bride/kelsie-d.jpg'
          ]"
          title="Kelsie Haakenson"
        />
      </div>
      <div class="text">
        <p class="title">
          Kelsie Haakenson
        </p>
        <p class="subtitle">
          Bridesmaid
        </p>
        <p>
          Kelsie was Lindsey’s college roommate. They met in the
          club Swing Kids at UW their freshman year. They bonded through
          theme parks, ski trips, and long talks about their lives.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'bride/katie-a.jpg',
            'bride/katie-b.jpg',
            'bride/katie-c.jpg',
            'bride/katie-d.jpg'
          ]"
          title="Katie Goldstein"
        />
      </div>
      <div class="text">
        <p class="title">
          Katie Goldstein
        </p>
        <p class="subtitle">
          Bridesmaid
        </p>
        <p>
          Katie is Lindsey’s cousin. She moved to
          New York right after Lindsey and made New York feel more like home.
          They have bonded throughout their lives over their shared love of
          Cabin in the Woods, film making, swimming pools, and steak.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'bride/abrianna-a.jpg',
            'bride/abrianna-b.jpg',
            'bride/abrianna-c.jpg',
            'bride/abrianna-d.jpg'
          ]"
          title="Abrianna Bell-Towne"
        />
      </div>
      <div class="text">
        <p class="title">
          Abrianna Bell-Towne
        </p>
        <p class="subtitle">
          Bridesmaid
        </p>
        <p>
          Abrianna is Alex’s younger sister. Lindsey met her for
          the first time after a production of Big Fish. They worked together
          at Lund Opsahl in Seattle and bonded over musical theater, calls
          over speakerphone, and work frustrations. Lindsey is very excited
          to be her sister.
        </p>
      </div>
    </section>

    <divider
      content="Groomspeople"
      right
    />

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'groom/daniele-a.jpg',
            'groom/daniele-b.jpg',
            'groom/daniele-c.jpg',
            'groom/daniele-d.jpg'
          ]"
          title="Daniele Bower"
        />
      </div>
      <div class="text">
        <p class="title">
          Daniele Bower
        </p>
        <p class="subtitle">
          Best Person
        </p>
        <p>
          Alex and Daniele met when they were in middle school at Daniele's mom's
          taekwondo school together. She is his fashion advisor and lifelong friend.
          He was a  groomsman in her wedding in 2018 and is excited to have her be his
          best person.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'groom/brendan-a.jpg',
            'groom/brendan-b.jpg',
            'groom/brendan-c.jpg',
            'groom/brendan-d.jpg'
          ]"
          title="Brendan Kellogg"
        />
      </div>
      <div class="text">
        <p class="title">
          Brendan Kellogg
        </p>
        <p class="subtitle">
          Groomsman
        </p>
        <p>
          Brendan is Alex’s friend from college and coworker at Square. They
          met when Brendan replaced Alex as the iSchool IT Student Assistant and they bonded
          over working on projects together, traveling to Hawaii, and honey walnut shrimp.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'groom/sean-a.jpg',
            'groom/sean-b.jpg',
            'groom/sean-c.jpg',
            'groom/sean-d.jpg'
          ]"
          title="Sean Ker"
        />
      </div>
      <div class="text">
        <p class="title">
          Sean Ker
        </p>
        <p class="subtitle">
          Groomsman
        </p>
        <p>
          Sean is a Alex’s roommate from college. They met in the dorms
          freshman year and lived together for 3 years. They bonded through cooking, feng
          shui, and traveling in England and Scotland together.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'groom/hiram-a.jpg',
            'groom/hiram-b.jpg',
            'groom/hiram-c.jpg'
          ]"
          title="Hiram Munn"
        />
      </div>
      <div class="text">
        <p class="title">
          Hiram Munn
        </p>
        <p class="subtitle">
          Groomsman
        </p>
        <p>
          Hiram is Alex’s friend from college. They met when Alex was preparing to
          take over the position of IUGA IT director from him. They bonded over
          IUGA drama, very tall hugs, and tacos.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'groom/ryan-a.jpg',
            'groom/ryan-b.jpg',
            'groom/ryan-c.jpg',
            'groom/ryan-d.jpg',
            'groom/ryan-e.jpg'
          ]"
          title="Ryan Church"
        />
      </div>
      <div class="text">
        <p class="title">
          Ryan Church
        </p>
        <p class="subtitle">
          Groomsman
        </p>
        <p>
          Ryan is Lindsey’s brother in-law. Alex first met
          Ryan in Lindsey’s parents’ kitchen after the St. Patrick’s Day Party
          while eating grilled cheese. They have bonded over computers, bidets,
          and buying space.
        </p>
      </div>
    </section>

    <section class="alternating-sides">
      <div class="images">
        <slide-show
          :images="[
            'groom/dakota-a.jpg',
            'groom/dakota-b.jpg',
            'groom/dakota-c.jpg',
            'groom/dakota-d.jpg',
            'groom/dakota-e.jpg',
            'groom/dakota-f.jpg'
          ]"
          title="Dakota Bell-Towne"
        />
      </div>
      <div class="text">
        <p class="title">
          Dakota Bell-Towne
        </p>
        <p class="subtitle">
          Groomsman
        </p>
        <p>
          Dakota is Alex’s younger brother. They have a shared
          love of Minecraft, biking, and bad puns. Alex is excited to have his
          oldest little brother stand with him on his wedding day.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import Divider from '@/components/Divider.vue';
import SlideShow from '@/components/SlideShow.vue';

export default {
  name: 'WeddingPartyPage',
  components: {
    Divider,
    SlideShow,
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-size: 3.5rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
}
</style>
