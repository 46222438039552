<template>
  <hr
    :data-hr-content="content"
    :class="{center, left, right}"
  >
</template>

<script>
export default {
  name: 'Divider',
  props: {
    content: {
      type: String,
      default: '',
    },
    left: Boolean,
    right: Boolean,
    center: Boolean,
  },
};
</script>

<style lang="scss" scoped>
hr {
  font-size: 3rem;

  &::after {
    background-color: var(--background-color);
    content: attr(data-hr-content);
    padding: 0 10px;
    position: absolute;
  }

  &.center {
    text-align: center;

    &::after {
      transform: translate(-50%, -50%);
    }
  }

  &.left {
    text-align: left;

    &::after {
      transform: translate(10%, -50%);
    }
  }

  &.right {
    text-align: right;

    &::after {
      transform: translate(-110%, -50%);
    }
  }
}
</style>
