<template>
  <div class="slide-show">
    <Flickity
      ref="flickity"
      :options="flickityOptions"
    >
      <progressive-img
        v-for="(image, index) in images"
        :key="index"
        class="carousel-cell"
        :placeholder="`/images-placeholder/${image}`"
        :src="getSrc(slide, index)"
        :aspect-ratio="1"
      />
    </Flickity>
  </div>
</template>

<script>
export default {
  name: 'SlideShow',
  props: {
    images: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    slide: 0,
    flickityOptions: {
      wrapAround: true,
    },
    loadedImages: {},
  }),
  mounted() {
    this.$nextTick(() => {
      this.$refs.flickity.on('change', (slide) => {
        this.slide = slide;

        this.$amplitude.track('Change Slide', {
          title: this.title,
          slide: this.slide,
        });
      });
    });
  },
  methods: {
    getSrc(slide, index) {
      if (this.loadedImages[index]) {
        return this.loadedImages[index];
      }

      if (slide === index) {
        this.loadedImages[index] = `/images/${this.images[index]}`;
        return this.loadedImages[index];
      }

      return '';
    },
  },
};
</script>

<!-- eslint-disable-next-line vue-scoped-css/require-scoped -->
<style lang="scss">
.flickity-button {
  padding: 1px 6px;
}

.flickity-page-dots {
  .dot {
    background-color: #bbb;
    margin: 0 4px;

    &.active,
    &:hover {
      background-color: #535353;
    }
  }
}

.flickity-prev-next-button {
  color: var(--background-accent-color-static);
  background-color: transparent;
  transition: 0.6s ease;
  border-radius: 3px;
  user-select: none;

  /* On hover, add a black background color with a little bit see-through */
  &:hover {
    color: var(--background-accent-color-static);
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>

<style lang="scss" scoped>
.slide-show {
  padding-bottom: 25px;
}
</style>
